var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dropdown__wrapper" }, [
    _c("div", { staticClass: "dropdown__title-wrapper" }, [
      _c(
        "div",
        { staticClass: "dropdown__title", on: { click: _vm.showList } },
        [
          _vm.value
            ? _c("span", [_vm._v(_vm._s(_vm.value))])
            : _c("span", [_vm._t("default")], 2)
        ]
      )
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.show,
            expression: "show"
          }
        ],
        staticClass: "dropdown__list-wrapper"
      },
      [
        _c(
          "ul",
          { staticClass: "dropdown__list" },
          _vm._l(_vm.options, function(option, i) {
            return _c(
              "li",
              {
                key: i,
                staticClass: "dropdown__item",
                class: {
                  "dropdown__item--remove": option === "Remove",
                  "dropdown__item--edit":
                    option === "Edit" || option === "Logout"
                },
                on: {
                  click: function($event) {
                    return _vm.emit(option)
                  }
                }
              },
              [
                option === "Edit"
                  ? _c("i", { staticClass: "ri-pencil-fill" })
                  : _vm._e(),
                option === "Remove"
                  ? _c("i", { staticClass: "ri-delete-bin-7-fill" })
                  : _vm._e(),
                option === "Logout"
                  ? _c("i", { staticClass: "ri-logout-circle-line" })
                  : _vm._e(),
                _vm._v(" " + _vm._s(option) + " ")
              ]
            )
          }),
          0
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }