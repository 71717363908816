<template>
  <div class="dropdown__wrapper">
    <div class="dropdown__title-wrapper">
      <div class="dropdown__title" @click="showList">
        <span v-if="value">{{ value }}</span>
        <span v-else>
          <slot></slot>
        </span>
      </div>
    </div>
    <div v-show="show" class="dropdown__list-wrapper">
      <ul class="dropdown__list">
        <li
          v-for="(option, i) in options"
          :key="i"
          class="dropdown__item"
          :class="{
            'dropdown__item--remove': option === 'Remove',
            'dropdown__item--edit': option === 'Edit' || option === 'Logout'
          }"
          @click="emit(option)"
        >
          <i v-if="option === 'Edit'" class="ri-pencil-fill"></i>
          <i v-if="option === 'Remove'" class="ri-delete-bin-7-fill"></i>
          <i v-if="option === 'Logout'" class="ri-logout-circle-line"></i>
          {{ option }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  props: {
    value: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      default: null
    },
    position: {
      type: String,
      default: 'top'
    }
  },
  data: () => ({
    show: false
  }),
  watch: {
    show() {
      const list = this.$el.childNodes[1]
      const doc = this.$root.$el
      if (this.show) {
        setTimeout(() => {
          doc.addEventListener('click', outsideClickListener)
        }, 0)
      }
      function isVisible(list) {
        return !!list && !!(list.offsetWidth || list.offsetHeight || list.getClientRects().length)
      }
      const outsideClickListener = event => {
        if (!this.show) {
          list.style = 'display: none'
          doc.removeEventListener('click', outsideClickListener)
        }
        if (!list.contains(event.target) && isVisible(list)) {
          this.show = false
          list.style = 'display: none'
          doc.removeEventListener('click', outsideClickListener)
        }
      }
    }
  },
  methods: {
    emit(option) {
      this.$emit('action', option)
      this.show = !this.show
    },
    showList() {
      this.show = !this.show
      const windowHeight = window.innerHeight
      const btn = this.$el.childNodes[0]
      const btnBottom = btn.getBoundingClientRect().bottom
      const btnReverseBottom = windowHeight - btnBottom
      const list = this.$el.childNodes[1]
      if (this.show) {
        setTimeout(() => {
          const listHeight = list.getBoundingClientRect().height
          if (btnReverseBottom < listHeight) {
            list.style.bottom = '100%'
          }
          list.style.opacity = 1
        }, 0)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'style';
</style>
